import api from '../';
// import {
//   SET_USER_ME,
//   SET_TEAMS_BY_USER,
//   SET_ACTIVE_MEMBERS,
//   SET_PENDING_MEMBERS,
//   DELETE_USER,
//   EDIT_MEMBER,
//   INVITE_USER,
// } from '../../Store/types';

export const getUserMe = async () => {
  const response = await api.get(`/users/me`);

  return response;
};

export const getUserRoles = async () => {
  const response = await api.get(`/users/me/roles`);

  console.log('[ GET ] User roles:', response);
  return response;
};

export const getTeamsByUser = async () => {
  const response = await api.get(`organizations`);
  console.log('[ GET ] Team by user:', response);
  return response;
  // if (response.status === 200) {
  //   // dispatch({
  //   //   type: SET_TEAMS_BY_USER,
  //   //   payload: response?.data,
  //   // });
  //   success(response?.data);
  // } else {
  //   error();
  // }
};
// export const getTeamByTeamId =
//   (id, success = () => true, error = () => true) =>
//   async (dispatch) => {
//     const response = await api.get(`teams${id}`);
//     console.log('[ GET ] Team by user using team id:', response);

//     if (response.status === 200) {
//       dispatch({
//         type: SET_TEAMS_BY_USER,
//         payload: response?.data,
//       });
//       success(response?.data);
//     } else {
//       error();
//     }
//   };

export const getActiveMembersByTeamId = async (
  id,
  page = 1,
  recursive,
  result = []
) => {
  try {
    let response = await api.get(`organizations/${id}/members?page=${page}`);
    console.log('[ GET ] Active members by team id:', response);

    if (recursive) {
      const page = response?.data?.pagination?.page;
      const total_pages = response?.data?.pagination?.total_pages;

      result = [...result, ...response?.data?.data];

      while (page < total_pages) {
        return getActiveMembersByTeamId(id, page + 1, true, result);
      }

      return {
        ...response,
        data: {
          ...response?.data,
          data: [...result],
        },
      };
    }
    return response;
  } catch (e) {
    console.log(e);
  }
};

export const getPendingMembersByTeamId = async (id, page) => {
  const response = await api.get(
    `organizations/${id}/invitations?status=pending&page=${page}`
  );
  console.log('[ GET ] Pending members by team id:', response);

  return response;
};

export const patchUserByUserId = async (
  teamId,
  userId,
  payload,
  success,
  error
) => {
  try {
    const response = await api.patch(
      `organizations/${teamId}/members/${userId}`,
      payload
    );
    console.log('[ PATCH ] user by user and team id', response);

    if (response.status === 200) {
      // dispatch({
      //   type: EDIT_MEMBER,
      //   payload: { userId, ...payload },
      // });
      success?.(response?.data);
      return response?.data;
    } else {
      error?.('Generic error');
      throw new Error('Error');
    }
  } catch (e) {
    console.log(e);
    error(e);
  }
};

export const patchOrganizationById = async (
  teamId,
  payload,
  success,
  error
) => {
  try {
    const response = await api.patch(`organizations/${teamId}`, payload);
    console.log('[ PATCH ] organization by team id', response);

    if (response.status === 200) {
      // dispatch({
      //   type: EDIT_MEMBER,
      //   payload: { userId, ...payload },
      // });
      success?.(response?.data);
    } else {
      error?.('Generic error');
    }
  } catch (e) {
    console.log(e);
    error?.(e);
  }
};

// export const patchInvitationByInvitationId =
//   (id, invitation_id, success = () => true, error = () => true) =>
//   async (dispatch) => {
//     const response = await api.patch(`teams${id}/invitation/${invitation_id}`);
//     console.log('[ PATCH ] invitation by invitation and team id', response);

//     if (response.status === 200) {
//       dispatch({
//         type: SET_TEAMS_BY_USER,
//         payload: response?.data,
//       });
//       success(response?.data);
//     } else {
//       error();
//     }
//   };

export const patchResendInvitation = async (
  id,
  invitation_id,
  success = () => true,
  error = () => true
) => {
  const response = await api.patch(
    `organizations/${id}/invitations/${invitation_id}/resend`
  );
  console.log('[ PATCH ] resend invitation', response);

  if (response.status === 200) {
    console.log('success');
    success(response?.data);
  } else {
    error();
  }
};

// export const postTeam =
//   (id, success = () => true, error = () => true) =>
//   async (dispatch) => {
//     const response = await api.patch(`teams${id}`);
//     console.log('[ POST ] create a team', response);

//     if (response.status === 200) {
//       dispatch({
//         type: SET_TEAMS_BY_USER,
//         payload: response?.data,
//       });
//       success(response?.data);
//     } else {
//       error();
//     }
//   };

export const postTeamInvitation = async (id, payload) => {
  const response = await api.post(`organizations/${id}/invitations`, payload);
  console.log('[ POST ] create an invitation by id', response);
  return response;
};

// export const postTeamMember =
//   (id, success = () => true, error = () => true) =>
//   async (dispatch) => {
//     const response = await api.patch(`teams${id}/members`);
//     console.log('[ POST ] create a member', response);

//     if (response.status === 200) {
//       dispatch({
//         type: SET_TEAMS_BY_USER,
//         payload: response?.data,
//       });
//       success(response?.data);
//     } else {
//       error();
//     }
//   };

export const deleteUserById = async (
  teamId,
  userId,
  success = () => true,
  error = () => true
) => {
  try {
    const response = await api.delete(
      `organizations/${teamId}/members/${userId}`
    );
    console.log('[ DELETE User by using team and user id', response);

    if (response.status === 200) {
      success(response?.data);
      return response?.data;
    } else {
      error();
    }
  } catch (e) {
    console.log(e);
    error();
    throw e;
  }
};

export const deleteInvitationById = async (
  teamId,
  invitationId,
  success = () => true,
  error = () => true
) => {
  try {
    const response = await api.delete(
      `organizations/${teamId}/invitations/${invitationId}`
    );
    console.log(
      '[ DELETE Invitation by using team and invitation id',
      response
    );

    if (response.status === 200) {
      success(response?.data);
      return response?.data;
    } else {
      throw new Error('Error');
    }
  } catch (e) {
    console.log(e);
    error();
    return e;
  }
};

export const getCampuses = async () => {
  const response = await api.get(`/campuses`);
  console.log('[ GET ] campuses list:', response);
  return response;
};
