import { useQuery } from 'react-query';
import { getUserRoles } from '../services/users';

export const useRoles = () => {
  // TODO check se la modifica funziona
  try {
    const result = useQuery(`roles`, async () => getUserRoles(), {
      retry: false,
    });

    return { ...result, data: result?.data?.data };
  } catch (error) {
    return null
  }
};
