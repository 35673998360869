import { useQuery } from 'react-query';
import { getPendingMembersByTeamId } from '../services/users';

export const usePendingMembers = (teamId, page = 1) => {
  const result = useQuery(
    [`pendingMembers-${teamId}`, page],
    async () => getPendingMembersByTeamId(teamId, page),
    { keepPreviousData: true }
  );
  return { ...result, data: result?.data?.data };
};
