import { useSessionStorage } from 'usehooks-ts';

export const useAccessToken = () => {
  const [accessToken, setAccessToken] = useSessionStorage(
    'accessToken',
    ''
  );

  return [accessToken, setAccessToken] as const;
};
