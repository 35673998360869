import moment from 'moment';
import { useQuery } from 'react-query';
import { getReportAccess } from '../services/dashboards';

export const useAccessReport = (id, startDate, endDate, key = '') => {
  const startDateMinusOneWeek = moment(startDate)
    .subtract(1, 'week')
    .format('YYYY-MM-DD[T]HH:mm:ss[Z]');
  const result = useQuery(
    [`accessReport-${key}`, id],
    async () =>
      await getReportAccess(
        id,
        startDateMinusOneWeek,
        moment(endDate).format('YYYY-MM-DD[T]HH:mm:ss[Z]')
      ),
    {
      retry: false,
    }
  );
  return { ...result, data: result?.data?.data };
};
