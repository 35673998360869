import api from '../';

export const getReportAccess = async (companyId, fromDate, toDate) => {
  const response = await api.get(
    `/organizations/${companyId}/report-accesses?from=${fromDate}&to=${toDate}`
  );

  return response;
};

export const getReportBooking = async (companyId, fromDate, toDate) => {
  const response = await api.get(
    `/organizations/${companyId}/report-bookings?from=${fromDate}&to=${toDate}`
  );

  return response;
};
