import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.API_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  timeout: 15000,
});

export const handleError = ({ message, data, status }) => {
  return Promise.reject({ message, data, status });
};

instance.interceptors.response.use(
  (response) => response,
  ({ message, response: { data, status, code } }) => {
    return handleError({ message, data, status });
  }
);

export const updateRequestHeaders = (token) => {
  instance.defaults.headers.common.Authorization = `Bearer ${token}`;
};

export default instance;
