import moment from 'moment';
import { useQuery } from 'react-query';
import { getReportBooking } from '../services/dashboards';

export const useBookingReport = (id = 1, from, to) => {
  const startDateMinusOneMonth = moment(from)
    .subtract(1, 'month')
    .format('YYYY-MM-DD[T]HH:mm:ss[Z]');

  const result = useQuery(
    `bookingReport-${id}`,
    async () =>
      await getReportBooking(
        id,
        startDateMinusOneMonth,
        moment(to).format('YYYY-MM-DD[T]HH:mm:ss[Z]')
      ),
    {
      retry: false,
    }
  );
  return { ...result, data: result?.data?.data };
};
