import { useState, useEffect, useCallback, useRef } from 'react';
// Create a hook to seperate out logic.
export const useHubspotUser = (userMe, hasLoaded) => {
  const [hasUserInfoSetted, setHasUserInfoSetted] = useState(false);

  useEffect(() => {
    if (!hasLoaded) {
      return;
    }

    if (!userMe || !Object.keys(userMe)?.length) {
      return;
    }

    // Create script component.
    let script = document.createElement('script');
    script.async = true;
    script.type = 'text/javascript';
    script.innerHTML = `
      var _hsq = window._hsq = window._hsq || [];
      var email = '${userMe?.email?.toString()}';
      var userId = '${userMe?.id?.toString()}';
      var fullName = '${userMe?.full_name?.toString()}';
      var firstName = '${userMe?.first_name?.toString()}';
      var lastName = '${userMe?.last_name?.toString()}';

      _hsq.push(['identify', {
        email: email,
        id: userId,
        fullName: fullName,
        firstName: firstName,
        lastName: lastName
      }]);
    `;

    document.body.appendChild(script);
    setHasUserInfoSetted(true);

    return () => {
      document.body.removeChild(script);
    };
  }, [userMe, hasLoaded]);

  return {
    hasUserInfoSetted,
  };
};
