import { useQuery } from 'react-query';
import { getActiveMembersByTeamId } from '../services/users';

export const useActiveMembers = (teamId, page = 1, recursive = false) => {
  const result = useQuery([`activeMembers-${teamId}`, page], async () =>
    getActiveMembersByTeamId(teamId, page, recursive), {
    enabled: Boolean(teamId),
  }
  );
  return { ...result, data: result?.data?.data };
};
