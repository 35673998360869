import 'regenerator-runtime/runtime';
import { UserProvider } from '@auth0/nextjs-auth0';
import '@talent-garden/react-components/main.css';
import '@talent-garden/react-components/theme.css';
import { useRouter } from 'next/dist/client/router';
import React, { useEffect } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ToastContainer, Zoom } from 'react-toastify';
import { useLocalStorage } from 'usehooks-ts';
import { useHubspotChat, useHubspotUser } from '../hooks';
import '../styles/globals.css';
import '../styles/media.css';
import '../styles/WeekPicker.css';
import * as ga from '../utils/analytics';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const Connect = ({ Component, pageProps }) => {
  const router = useRouter();
  const { hasLoaded } = useHubspotChat('4808925');
  const [userMe] = useLocalStorage('userMe', {});
  useHubspotUser(userMe, hasLoaded);

  useEffect(() => {
    // disable analytics for development environments
    if (process.env.NODE_ENV !== 'production') {
      return;
    }

    const handleRouteChange = (url) => {
      ga.pageview(url);
    };
    // When the component is mounted, subscribe to router changes
    // and log those page views
    router.events.on('routeChangeComplete', handleRouteChange);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method
    // eslint-disable-next-line consistent-return
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  return (
    <QueryClientProvider client={queryClient}>
      <UserProvider>
        <Component {...pageProps} />
        <ToastContainer
          transition={Zoom}
          position='top-center'
          autoClose={3000}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme='colored'
        />
      </UserProvider>
      <ReactQueryDevtools />
    </QueryClientProvider>
  );
};

export default Connect;
