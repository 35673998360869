import { useQuery } from 'react-query';
import { getTeamsByUser } from '../services/users';

export const useOrganizations = () => {
  // TODO check se la modifica funziona
  try {
    const result = useQuery(`organizations`, async () => getTeamsByUser(), {
      retry: false,
    });

    return { ...result, data: result?.data?.data };
  } catch (error) {
    return null;
  }
};
